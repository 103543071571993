import React from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Link from 'atomic-design/atoms/link';
import theme from 'styles/theme';
import PageSection from 'atomic-design/atoms/page-section';
import Button from 'atomic-design/atoms/button';
import {
  ContactUsButtonsContainer,
  ContactUsContainer,
  ContactUsH5,
  ContactUsH6,
  ContactUsTextContainer,
  StyledContactUsH5,
} from './styles';
import BackgroundGradient from 'atomic-design/atoms/button-gradient/styles';
import ButtonGradient from 'components/atomic-design/atoms/button-gradient';
import useScrollToForm from 'hooks/UseScrollToForm';

const LetUsAboutSection = ({
  title,
  subtitle,
  buttonTitle,
  background,
  scrollToForm,
  showJoinTeamButton = true,
}) => {
  const scrollToContactForm = useScrollToForm('form[name="contact"]');

  return (
    <PageSection background={background}>
      <ContactUsContainer>
        <ContactUsTextContainer>
          {subtitle ? (
            <>
              <StyledContactUsH5>{title}</StyledContactUsH5>
              <ContactUsH6>{documentToReactComponents(subtitle)}</ContactUsH6>
            </>
          ) : (
            <ContactUsH5>{title}</ContactUsH5>
          )}
        </ContactUsTextContainer>
        <ContactUsButtonsContainer>
          {scrollToForm ? (
            <BackgroundGradient variant="light">
              <Button
                aria-label={'contact-button'}
                variant="outlined"
                onClick={scrollToContactForm}
              >
                <span>{buttonTitle}</span>
              </Button>
            </BackgroundGradient>
          ) : (
            <>
              <ButtonGradient variant="light" title={buttonTitle} to="/contact" />
              {!subtitle && showJoinTeamButton && (
                <Link to="/careers">
                  <Button variant="outlined">Join our team</Button>
                </Link>
              )}
            </>
          )}
        </ContactUsButtonsContainer>
      </ContactUsContainer>
    </PageSection>
  );
};

LetUsAboutSection.propTypes = {
  buttonTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.instanceOf(Object),
  background: PropTypes.string,
  scrollToForm: PropTypes.bool,
};

LetUsAboutSection.defaultProps = {
  buttonTitle: 'Tell us about your project',
  subtitle: null,
  background: theme.newTheme.color.background.darkBlue,
};

export default LetUsAboutSection;
