import React from 'react';
import Link from 'atomic-design/atoms/link';

const checkListSectionsData = [
  {
    title: <h6>We're a perfect match if you...</h6>,
    isPositive: true,
    checkListItemsData: [
      <p>value high-quality software craftsmanship</p>,
      <p>
        want to scale without being burned by unreliable freelancers and full-of-baloney agencies
      </p>,
      <p>crave pricing certainty</p>,
      <p>hate corporate BS</p>,
      <p>need a partner you can trust to help you hit your next milestone</p>,
      <p>think agile rocks</p>,
    ],
  },
  {
    title: (
      <h6>
        We're <u>not</u> soulmates if you...
      </h6>
    ),
    isPositive: false,
    checkListItemsData: [
      <p>want a team of order-takers who don't think or ask questions</p>,
      <p>want to hire one rockstar developer</p>,
      <p>gotta launch your MVP yesterday</p>,
      <p>think software is built in a silo</p>,
      <p>need 200 developers and don't care how you get them</p>,
      <p>are looking for the cheapest option</p>,
      <p>are mean</p>,
    ],
  },
  {
    title: <h6>We do these things really well...</h6>,
    isPositive: true,
    checkListItemsData: [
      <p>Full-stack development</p>,
      <Link to="/services/mvp-development">MVP development</Link>,
      <Link to="/services/team-augmentation">Team augmentation</Link>,
      <p>User-centered UI/UX design</p>,
      <p>Software architecture</p>,
      <Link to="/services/consultancy">Consultancy</Link>,
      <p>QA automation + and more...</p>,
    ],
  },
];

export default checkListSectionsData;
