import React, { useRef } from 'react';

import MetaTags from 'core/meta-tags/index';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { LinkedInIconOutline } from 'ui/svg';
import Layout from 'core/layout';
import PageHeader from 'atomic-design/atoms/page-header';
import ImgAndInformation from 'atomic-design/molecules/img-and-information';
import CheckList from 'atomic-design/molecules/check-list';
import TeamItem from 'atomic-design/molecules/team-item';
import theme from 'styles/theme';
import CallToAction from 'atomic-design/molecules/call-to-action';
import ManifestoSection from 'atomic-design/organisms/manifesto-section';
import TestimonialCard from 'atomic-design/molecules/testimonial-card';
import PageSection from 'atomic-design/atoms/page-section';
import Button from 'components/atomic-design/atoms/button';
import checkListSectionsData from 'domain/about-us-checklist.js';
import { Container, Row, Col } from 'react-grid-system';
import notANumberList from 'domain/not-a-number-list';

import {
  PageHeaderContainer,
  SectionTitle,
  TeamContainer,
  ContainerGrid,
  ButtonTeamContainer,
  NaNList,
  NaNListItem,
  CheckListSectionList,
  CheckListSectionItem,
  TeamList,
  ThoughtP,
  ThoughtH5,
  CallToActionContainer,
  MissionContainer,
  MissionBoxContainer,
  MissionH4,
  MissionSectionBackground,
  GreyH5,
  Content,
  TeamTitle,
  ChecklistCol,
} from 'styles/pages/about-us-styles';

import { renderLineBreak } from 'common/string-helpers';
import LetUsAboutSection from 'atomic-design/organisms/let-us-about-section';

export const AboutUsQuery = graphql`
  query AboutUsQuery {
    allContentfulPage(filter: { url: { eq: "about-us" } }) {
      edges {
        node {
          headerTitle {
            json
          }
          headerSubtitle
          headerDescription {
            headerDescription
          }
          metatagImage {
            file {
              url
            }
          }
          metatagTitle
          metatagDescription
        }
      }
    }
    allContentfulTeamMemberAboutUs(sort: { fields: order, order: ASC }) {
      edges {
        node {
          name
          linkedinUrl
          role
          description
          skills
          image {
            contentful_id
          }
          order
          pageToRender
        }
      }
    }
    allContentfulTestimonialFeatured(filter: { pageId: { eq: "aboutUs" } }) {
      edges {
        node {
          title {
            json
          }
          description {
            description
          }
          author {
            image {
              contentful_id
            }
            name
            role
          }
        }
      }
    }
  }
`;

const AboutUsPage = ({ data, location }) => {
  const teamRef = useRef();
  const pageHeaderData = data.allContentfulPage.edges[0].node;
  const testimonialFeatured = data.allContentfulTestimonialFeatured.edges[0].node;
  const metatagImage = data.allContentfulPage.edges[0].node.metatagImage.file.url;
  const metatagDescription = data.allContentfulPage.edges[0].node.metatagDescription;
  const metatagTitle = data.allContentfulPage.edges[0].node.metatagTitle;

  const teamMembersData = data.allContentfulTeamMemberAboutUs.edges.filter(
    edge => edge.node.pageToRender && edge.node.pageToRender.includes('about-us')
  );

  const iconArgs = {
    size: theme.newTheme.spacing(12),
    color: theme.newTheme.color.primary.main,
  };

  const linkedin = {
    name: 'LinkedIn',
    icon: <LinkedInIconOutline {...iconArgs} />,
  };

  const jaggedArrayOfTwoMembers = teamMembersData.reduce((rows, member, index) => {
    return (index % 2 == 0 ? rows.push([member]) : rows[rows.length - 1].push(member)) && rows;
  }, []);

  const handleAnchor = () => {
    const headerOffset = 85;
    const elementPosition = teamRef.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <Layout path={location.pathname} bodyType="xWide">
      <MetaTags
        title={metatagTitle}
        description={metatagDescription}
        charSet="utf-8"
        image={metatagImage}
        viewport="width=device-width, initial-scale=1"
        type="website"
      />
      <PageSection margin={`0 0 ${theme.newTheme.spacing(16)} 0`}>
        <PageHeaderContainer>
          <PageHeader
            title={pageHeaderData.headerSubtitle}
            subTitle={documentToReactComponents(pageHeaderData.headerTitle.json)}
            description={renderLineBreak(pageHeaderData.headerDescription.headerDescription)}
          />
          <ButtonTeamContainer>
            <Button variant="gradient" onClick={handleAnchor}>
              Meet the team
            </Button>
          </ButtonTeamContainer>
        </PageHeaderContainer>
      </PageSection>

      <PageSection background={`${theme.newTheme.color.primary.light}20`}>
        <Content>
          <TestimonialCard
            title={documentToReactComponents(testimonialFeatured.title.json)}
            description={testimonialFeatured.description.description}
            image={testimonialFeatured.author.image.contentful_id}
            authorName={testimonialFeatured.author.name}
            authorRol={testimonialFeatured.author.role}
            alt={`${testimonialFeatured.author.name} - ${testimonialFeatured.author.rol}`}
            textAlign="center"
            nameColor={theme.newTheme.color.primary.main}
          />
        </Content>
      </PageSection>

      <PageSection>
        <SectionTitle>
          <h3>
            At NaNLABS you're <span>Not a Number</span>
          </h3>
        </SectionTitle>
        <NaNList>
          {notANumberList.map((notANumberItem, index) => (
            <NaNListItem key={`NaNList-${index}`}>
              <ImgAndInformation
                subTitle={notANumberItem.subTitle}
                title={notANumberItem.title}
                description={notANumberItem.description}
                image={notANumberItem.image}
                altImage={notANumberItem.altImg}
                leftImage={index % 2 === 0}
              />
            </NaNListItem>
          ))}
        </NaNList>
      </PageSection>

      <PageSection margin={`${theme.newTheme.spacing(16)} 0`}>
        <SectionTitle>
          <h3>
            Software development <span>without selling your soul</span>
          </h3>
        </SectionTitle>
        <Container>
          <CheckListSectionList>
            <Row gutterWidth={90}>
              {checkListSectionsData.map(section => (
                <Col xs={12} sm={12} md={12} lg={4} component={ChecklistCol}>
                  <CheckListSectionItem key={section.title}>
                    <CheckList
                      title={section.title}
                      pointsList={section.checkListItemsData}
                      isPositive={section.isPositive}
                    />
                  </CheckListSectionItem>
                </Col>
              ))}
            </Row>
          </CheckListSectionList>
        </Container>
      </PageSection>

      <PageSection
        background={theme.newTheme.color.gradient.background.primary.main}
        padding={`${theme.newTheme.spacing(26)} 0`}
      >
        <ThoughtH5>
          Thought, precision and agility at the heart of every collaboration & product we craft.
        </ThoughtH5>
        <ThoughtP>THINK. BUILD. LAUNCH.</ThoughtP>
      </PageSection>

      <PageSection margin={`${theme.newTheme.spacing(26)} 0`}>
        <TeamContainer>
          <TeamTitle>
            <GreyH5 ref={teamRef}> MEET THE TEAM</GreyH5>
            <h3>
              (Some of) <span>the faces</span> <span>behind the Lab</span>
            </h3>
          </TeamTitle>
          <Container fluid={true} component={ContainerGrid}>
            <TeamList>
              <Row component={ContainerGrid}>
                {jaggedArrayOfTwoMembers.map((pairMembers, index) =>
                  pairMembers.map(teamMember => (
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      key={`Column ${teamMember.title}`}
                      component={ContainerGrid}
                    >
                      <TeamItem
                        image={teamMember.node.image.contentful_id}
                        title={teamMember.node.name}
                        role={teamMember.node.role}
                        description={teamMember.node.description}
                        skillList={teamMember.node.skills}
                        socialList={[{ ...linkedin, to: teamMember.node.linkedinUrl }]}
                        leftImage={index % 2 === 0}
                      />
                    </Col>
                  ))
                )}
              </Row>
            </TeamList>
          </Container>
        </TeamContainer>
      </PageSection>

      <PageSection>
        <CallToActionContainer>
          <CallToAction
            sectionTitle="Ready to launch your project?"
            buttonTitle="Get in touch"
            to="/contact"
          />
          <GreyH5>We don't byte</GreyH5>
        </CallToActionContainer>
      </PageSection>

      <PageSection padding={`${theme.newTheme.spacing(16)} 0`}>
        <MissionContainer>
          <MissionSectionBackground />
          <MissionBoxContainer>
            <GreyH5>MISSION</GreyH5>
            <MissionH4>Our big, juicy why</MissionH4>
            <p>
              We exist to <b>empower people</b> and organizations through technology. More than
              things, we focus on delivering value.
            </p>
          </MissionBoxContainer>
        </MissionContainer>
      </PageSection>

      <PageSection margin={`0 0 ${theme.newTheme.spacing(12)} 0`}>
        <ManifestoSection
          subtitle="STUFF WE STAND FOR"
          title={['The NaNLABS ', <span>Manifesto</span>]}
          description={[
            'Yet another list of values?',
            <span>Nope. This is the code we live by:</span>,
          ]}
        />
      </PageSection>
      <LetUsAboutSection title="You've made it this far. Let's take the next step. This could be the start of something special..." />
    </Layout>
  );
};

export default AboutUsPage;
