import styled from 'styled-components';

export const ContactUsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const ContactUsTextContainer = styled.div`
  display: flex;
  width: 60%;
  padding-bottom: ${({ theme }) => `${theme.newTheme.spacing(12)}`};
  padding-top: ${({ theme }) => `${theme.newTheme.spacing(12)}`};
  flex-direction: column;
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
  }
`;

export const ContactUsH5 = styled.h5`
  font-size: ${({ theme }) => `${theme.newTheme.font.title4.size}`};
  line-height: ${({ theme }) => `${theme.newTheme.font.title4.lineHeight}`};
  font-weight: ${({ theme }) => `${theme.newTheme.font.weight.bold}`};
  color: ${({ theme }) => `${theme.newTheme.color.white}`};
  b {
    color: ${({ theme }) => `${theme.newTheme.color.white}`};
    b {
      color: ${({ theme }) => `${theme.newTheme.color.white}`};
    }
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => `${theme.newTheme.font.title5.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title5.lineHeight}`};
  }
`;

export const ContactUsH6 = styled.h6`
  margin-top: 0;
  font-size: ${({ theme }) => `${theme.newTheme.font.title6.size}`};
  line-height: ${({ theme }) => `${theme.newTheme.font.title6.lineHeight}`};

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => `${theme.newTheme.font.text1.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.text1.lineHeight}`};
  }
  b {
    color: ${({ theme }) => `${theme.newTheme.color.white}`};
    font-weight: ${({ theme }) => `${theme.newTheme.font.weight.bold}`};
  }
  p {
    color: ${({ theme }) => `${theme.newTheme.color.white}`};
    margin-bottom: 0;
  }
`;

export const ContactUsButtonsContainer = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: ${({ theme }) => `${theme.newTheme.spacing(12)}`};
  padding-top: ${({ theme }) => `${theme.newTheme.spacing(12)}`};
  gap: ${({ theme }) => `${theme.newTheme.spacing(10)}`};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
    align-items: start;
  }

  a:nth-child(2) {
    button {
      border: ${({ theme }) => `2px solid ${theme.newTheme.color.white}`} !important;
      background: transparent;
      color: ${({ theme }) => theme.newTheme.color.white};

      &:hover {
        background-color: ${({ theme }) => theme.newTheme.color.background.indigo};
      }

      @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
`;

export const StyledContactUsH5 = styled(ContactUsH5)`
  font-size: ${({ theme }) => `${theme.newTheme.font.title3.size}`};
  line-height: ${({ theme }) => `${theme.newTheme.font.title3.lineHeight}`};
  margin-bottom: 0;

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => `${theme.newTheme.font.title4.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title4.lineHeight}`};
`;
