const notANumberList = [
  {
    subTitle: '"Enough is enough!"',
    title: 'HOW IT STARTED',
    description:
      'Dev-built in 2013, NaNLABS was born out of frustration. Tired of companies producing sloppy software and treating people like numbers. We said “enough!” (or “¡basta!” in Spanish). The world deserves a software studio where caring for people and technical excellence come first. So that’s what we created. ',
    image: 'consultancy-1200',
    altImage: 'an office image',
  },
  {
    subTitle: 'We don’t code, we craft.',
    title: "HOW IT'S GOING",
    description:
      'From 4 to 50 and growing, our cross-functional squad is trusted and loved by fast-growing startups and enterprise companies across 11+ industries. We bring a people-first approach to building technology, and take our craft seriously. When you work with us, you get sooo much more than code.',
    image: 'now-1200',
    altImage: 'an office image',
  },
];

export default notANumberList;
